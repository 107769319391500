import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Form from "../../../components/common/Form";
import TopBanner from "../../../components/common/TopBanner";

const BookEditing = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/banner.png"} />

      <section className="grid grid-cols-1 p-14 gap-2" data-aos="fade-up">
        <div>
          <div className="flex items-center gap-5">
            <div className="w w-3 h-12 bg-[#A7AB43]" data-aos="fade-right"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl" data-aos="fade-left">
              Book Editing
            </h3>
          </div>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up">
            A crucial step in the writing process is editing. They help make
            your writing more successful and, as a result, make your ideas more
            understandable. Rereading your copy while editing will help you find
            any more serious problems with organisation, paragraph structure,
            and substance.
          </p>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up">
            Basic editing involves the editor correcting grammatical, spelling,
            punctuation, improper sentence length, and vocabulary. The
            sentences' intentions remain unchanged. There is no attempt to
            change the author's writing style. The sentences' inherent
            simplicity is preserved.
          </p>
          <p className="text-lg mt-2 tracking-widest font-medium" data-aos="fade-up">
            Price: ₹ 0.45 per word plus GST.
          </p>
        </div>
      </section>
      <section className="mt-2 mb-6" data-aos="fade-up">
        <Form />
      </section>
    </div>
  );
};

export default BookEditing;
