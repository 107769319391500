import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './PublishingPlan.css'
import Form from "../../components/common/Form";
import PlanOne from "./PlanOne";
import PlanTwo from "./PlanTwo";
import PlanThree from "./PlanThree";
import TopBanner from "../../components/common/TopBanner";

const PublishingPlan = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/abbanner.png"} />
      <section className="grid grid-cols-1 p-14 gap-2">
        <div data-aos="fade-up">
          <div className="flex items-center gap-5">
            <div className="w w-3 h-12 bg-[#A7AB43]"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl">
              OUR BOOK PUBLISHING PLAN
            </h3>
          </div>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up" data-aos-delay="200">
            Get your book published by India's most reputable publishing house.
            Choose a Publication Package to get started with your book
            publishing. Full range of publishing services, anything from
            designing book covers through publishing, advertising, and
            marketing.
          </p>
        </div>
      </section>

      <section className="p-5 md:p-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center   ">
        <div data-aos="fade-up" data-aos-delay="200" className="plan-center">
          <PlanOne />
        </div>
        <div data-aos="fade-up" data-aos-delay="400" className="plan-center">
          <PlanTwo />
        </div>
        <div data-aos="fade-up" data-aos-delay="600" className="plan-center">
          <PlanThree />
        </div>
      </section>

      <section className="mt-2 mb-6" data-aos="fade-up" data-aos-delay="800">
        <Form />
      </section>
    </div>
  );
};

export default PublishingPlan;
