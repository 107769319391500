import React, { useEffect, useState } from "react";
import Form from "../../components/common/Form";
import { Link, useParams } from "react-router-dom";
const bookstore_url = `https://prominantbook.azurewebsites.net/api/v1/GetBookInBookStorebyBookId`;
const document_url = `https://prominantbook.azurewebsites.net/api/v1/getDocumentbyDocumentId`;

const InitialDetials = {
  _id: "",
  BookId: 0,
  NameofBook: "",
  BookPrice: 0,
  BookAuthor: "",
  BookSummary: "",
  BookISBNNumber: 0,
  DateofPublication: "",
  Booklanguage: "",
  Numberofpages: 0,
  CategoryId: 0,
  __v: 0,
  createdAt: "",
  updatedAt: "",
};

const BookDetail = () => {
  const { id } = useParams();
  const [bookDetails, setBookDetails] = useState(InitialDetials);
  const [bookCover, setBookCover] = useState("");

  useEffect(() => {
    const getBookDetails = async () => {
      try {
        // Fetch book details
        const bookResponse = await fetch(`${bookstore_url}/${id}`);
        const bookData = await bookResponse.json();
        setBookDetails(bookData.data);

        // Fetch document details to get the cover image
        const documentResponse = await fetch(`${document_url}/${id}`);
        const documentData = await documentResponse.json();
        if (documentData.data && documentData.data.length > 0) {
          const cover = documentData.data[0].files[0];
          setBookCover(cover);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    getBookDetails();
  }, [id]);

  return (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-4">
        <div>
          <h2 className="font-bold text-4xl md:text-6xl text-center leading-snug">
            Accomplish your
            <br /> goal of being a <br />
            <span className="text-[#F9671D]">Published Author.</span>
          </h2>
          <p className="text-center text-lg md:text-xl font-medium tracking-wider mt-4">
            Join up for the fastest Book Publishing Platform.
          </p>
          <div className="flex justify-center mt-4">
            <Link
              className="text-white py-2 md:py-3 px-4 md:px-6 bg-[#A7AB43] rounded-full shadow-md"
              to="/"
            >
              Start Publishing
            </Link>
          </div>
        </div>
        <div className="flex justify-center items-center mt-4 md:mt-0">
          <img
            className="max-w-full h-auto"
            src={
              "https://tse3.mm.bing.net/th?id=OIP.6YSPKprwpnJkbyFR-eGqNQHaFR&pid=Api&P=0&h=180"}
            alt="banner"
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-4">
        <div className="flex justify-center items-start">
          <img
            className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2"
            src={bookCover}
            alt="Book Cover"
          />
        </div>
        <div className="flex flex-col items-start gap-2 p-4">
          <h3 className="text-3xl text-[#F9671D] font-semibold">
            {bookDetails.NameofBook}
          </h3>
          <p className="font-medium">Author Name: {bookDetails.BookAuthor}</p>
          <p className="font-medium">
            Book ISBN No: {bookDetails.BookISBNNumber}
          </p>
          <p className="font-medium">
            Date of Publication:{" "}
            {new Date(bookDetails.DateofPublication).toLocaleDateString()}
          </p>
          <p className="font-medium">
            Number of pages: {bookDetails.Numberofpages}
          </p>
          <p className="font-medium">Book Category: {bookDetails.CategoryId}</p>
          <p className="font-medium">Book Summary:</p>
          <p className="text-justify">{bookDetails.BookSummary}</p>
          <div className="flex flex-wrap justify-start gap-2 mt-4">
            <button className="bg-[#084E8E] text-white py-2 px-4 rounded shadow-md">
              Add to cart
            </button>
            <button className="bg-black text-white py-2 px-4 rounded shadow-md">
              Buy Now
            </button>
          </div>
        </div>
      </section>
      <section className="my-6">
        <Form />
      </section>
    </div>
  );
};

export default BookDetail;
