import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function TopBanner({ imagesrc }) {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []);

  return (
    <section
      className="grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-4"
      data-aos="fade-up"
    >
      <div>
        <h2 className="font-bold text-3xl md:text-6xl text-center leading-snug md:leading-normal">
          <span data-aos="fade-down" data-aos-delay="200">Accomplish your</span>
          <br />
          <span data-aos="fade-down" data-aos-delay="400">goal of</span> being a{" "}
          <br />
          <span
            className="text-[#F9671D]"
            data-aos="fade-down"
            data-aos-delay="600"
          >
            Published Author.
          </span>
        </h2>
        <p
          className="text-center text-xl font-medium tracking-wider"
          data-aos="fade-down"
          data-aos-delay="800"
        >
          Join up for the fastest Book Publishing <br /> Platform.
        </p>
        <div
          className="flex justify-center mt-6"
          data-aos="fade-down"
          data-aos-delay="1000"
        >
          <Link
            className="text-white py-3 px-6 bg-[#A7AB43] place-items-center rounded-full shadow-md"
            to="/"
          >
            Start Publishing
          </Link>
        </div>
      </div>
      <div
        className="flex justify-center items-center mt-4 md:mt-0"
        data-aos="fade-down"
        data-aos-delay="1200"
      >
        <img
          className="w-full md:w-[75%]"
          src={imagesrc}
          alt="bookstore banner"
        />
      </div>
    </section>
  );
}
