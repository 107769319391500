import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Form from "../../../components/common/Form";
import TopBanner from "../../../components/common/TopBanner";

const BookMarketing = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/banner.png"} />

      <section className="grid grid-cols-1 p-14 gap-2" data-aos="fade-up">
        <div>
          <div className="flex items-center gap-5">
            <div className="w w-3 h-12 bg-[#A7AB43]" data-aos="fade-right"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl" data-aos="fade-left">
              Book Marketing
            </h3>
          </div>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up">
            Not everyone will read what you wrote just because you wrote it. You
            need to spread the word, and our team of marketing specialists
            creates plans to give you an excellent package with all the
            resources required to turn your book into a best seller.
          </p>
          <h4 className="text-lg mt-4 tracking-widest font-semibold text-[#A7AB43]" data-aos="fade-up">
            Book Video Trailer
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up">
            The first glimpse your readers see of your book is called a teaser.
            We'll make and share a 30- to 60-second video preview of your book
            on popular video-sharing sites like Vimeo and YouTube.
          </p>
          <p className="text-lg mt-2 tracking-widest font-medium" data-aos="fade-up">
            Price: ₹ 8,000 plus GST.
          </p>
          <h4 className="text-lg mt-4 tracking-widest font-semibold text-[#A7AB43]" data-aos="fade-up">
            Social Media Promotion
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up">
            The foundation of book marketing is now social media. A professional
            appearance is crucial for an author, and the same goes for his
            social networking sites. How does it make you feel, though, when
            someone looks at your profile and sees your book listed as a cover?
            Does it increase the appeal and visibility of your book and you? The
            answer is, indeed, it does. Our team is committed to setting up your
            social media accounts (Facebook and Instagram) and performing
            certain fundamental tasks on these pages to improve the book's
            visibility.
          </p>
          <p className="text-lg mt-4 tracking-widest" data-aos="fade-up">This includes:</p>
          <ul className="text-lg mt-1 tracking-widest list-disc pl-6" data-aos="fade-up">
            <li className="">Create Facebook and Instagram pages.</li>
            <li>Customizing the page.</li>
            <li>Facebook Page Promotions - 2 Weeks.</li>
            <li>Promoted Posts - 2 per week.</li>
            <li>Video Trailer Promotions (If any).</li>
          </ul>
        </div>
      </section>
      <section className=" mt-2 mb-6" data-aos="fade-up">
        <Form />
      </section>
    </div>
  );
};

export default BookMarketing;
