import React, { useEffect } from "react";
import Form from "../../../components/common/Form";
import TopBanner from "../../../components/common/TopBanner";
import AOS from "aos";
import "aos/dist/aos.css";

const BookDistrbutions = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/banner.png"} />
      <section className="grid grid-cols-1 p-14 gap-2" data-aos="fade-up">
        <div>
          <div className="flex items-center gap-5">
            <div className="w w-3 h-12 bg-[#A7AB43]" data-aos="fade-right"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl" data-aos="fade-left">
              Book Distrbutions
            </h3>
          </div>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up">
            Today's authors must must have their own website. It functions as a
            digital platform for your book as well as you as the author. You can
            interact with potential readers on the website in addition to
            promoting your book.
          </p>
          <p className="text-lg mt-1 tracking-widest font-medium" data-aos="fade-up">
            Price: ₹ 3,000 plus GST.
          </p>
        </div>
      </section>
      <section className=" mt-2 mb-6" data-aos="fade-up">
        <Form />
      </section>
    </div>
  );
};

export default BookDistrbutions;
