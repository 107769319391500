import React from "react";
import { MdCheck } from "react-icons/md";

const PlanThree = () => {
  return (
    <div className="w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md ">
      <div className="text-center bg-[#FFC01F] py-6 px-10 h-40">
        <h3 className="mt-2 font-medium text-xl">ADVANCE</h3>
        <p className="mt-2 text-justify">
          Increase sales by promoting your book to a wider audience.
        </p>
      </div>
      <div className="py-4 px-10 text-center">
        <h3 className="mt-2 font-medium text-xl">₹ 11999</h3>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex flex-col text-start">
            <span>Guided publishing</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Dedicated Publishing Manager</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Cover Design
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Standard Cover Design</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>5 Cover Sample</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex flex-col text-start">
            <span>Paperback cover</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>2 Revisions*</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Interior Designing
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Paperback & ebook</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>B/W Interior Type</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex flex-col text-start">
            <span>Basic Interior Design</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex flex-col text-start">
            <span>2 Interior Revision Round</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Book Formatting
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Book Formatting</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Black & White Interior</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex flex-col text-start">
            <span>Proof Reading</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex flex-col text-start">
            <span>1 Revisions*</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
      </div>
      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Content Security
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Copyright</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>ISBN</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
      </div>
      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Manuscript Editing
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Manuscript Proofreading</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Basic Spelling Correction</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
      </div>
      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Marketing Plans
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>E-Author Certificate</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Marketing Guidance</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Online Listing</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span> Author Website </span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Book Video Trailer</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
      </div>
      <div className="w-full py-4 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Printing
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Author Copies</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Digital Proof</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
      </div>
      <div className="w-full py-4 mb-6 px-10 text-center">
        <p className="text-center text-[#F9671D] font-semibold mb-2">
          Royalty & Sales Support
        </p>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Author Dashboard</span>
            <span className="text-[#C4C4C4] line-through"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>Monthly Royalty Update</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
        <div className="flex justify-start gap-2">
          <span className="mt-1">
            <MdCheck className="text-[#F9671D]" size={18} />
          </span>

          <p className="flex justify-start text-start">
            <span>70% Profit Share</span>
            <span className="text-[#C4C4C4]"></span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanThree;
