import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [issubOpen, setIssubOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = () => {
    setIssubOpen(!issubOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIssubOpen(false);
  };

  // Check if any sub-menu item under "Services" is active
  const isServicesActive = ["/book-design", "/book-editing", "/marketing", "/distribution", "/others"].includes(location.pathname);

  return (
    <nav className="bg-[#A7AB43] px-4 py-4 sm:px-12 flex items-center justify-between flex-wrap sticky top-0 z-50 Navbar-setup">
      <div className="block sm:hidden flex items-center justify-between w-full">
        <a href="https://prominentpublication.com">
          <img src="https://prominentpublication.com/image/logo.png" alt="Prominent Publication Logo" style={{ width: '70px', height: '50px' }} />
        </a>
        <button
          onClick={toggleMenu}
          className="text-black focus:outline-none ml-auto transition-transform duration-900 ease-in-out"
        >
          {isOpen ? (
            <XMarkIcon className="h-8 w-8 text-black transform transition-transform duration-900 ease-in-out" />
          ) : (
            <Bars3Icon className="h-8 w-8 transform transition-transform duration-900 ease-in-out" />
          )}
        </button>
      </div>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } w-full block flex-grow sm:flex sm:items-center sm:w-auto`}
      >
        <div className="text-sm sm:flex-grow">
          <NavLink
            onClick={toggleMenu}
            to="/"
            exact
            activeClassName="text-black"
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-black mr-8 font-semibold text-lg"
          >
            Home
          </NavLink>
          <NavLink
            onClick={toggleMenu}
            to="/about"
            activeClassName="text-black"
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-black mr-8 font-semibold text-lg"
          >
            About
          </NavLink>
          <NavLink
            onClick={toggleMenu}
            to="/publishing-plan"
            activeClassName="text-black"
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-black mr-8 font-semibold text-lg"
          >
            Publishing Plan
          </NavLink>
          <NavLink
            onClick={toggleMenu}
            to="/Royalty"
            activeClassName="text-black"
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-black mr-8 font-semibold text-lg"
          >
            Royalty
          </NavLink>
          <NavLink
            onClick={toggleMenu}
            to="/bookstore"
            activeClassName="text-black"
            className="block mt-4 sm:inline-block sm:mt-0 text-white hover:text-black mr-8 font-semibold text-lg"
          >
            Bookstore
          </NavLink>
          <div
            className="group inline-block relative mt-2 sm:mt-0 sm:flex-grow-0"
            onMouseEnter={toggleSubMenu}
            onMouseLeave={toggleSubMenu}
          >
            <button className={`py-2 pr-6 rounded inline-flex items-center font-semibold text-lg ${isServicesActive ? "text-black" : "text-white"} hover:text-black`}>
              <span>Services</span>
            </button>
            {issubOpen && (
              <ul className="absolute bg-[#A7AB43] text-white pt-1 shadow-2xl rounded-t">
                <li>
                  <NavLink
                    onClick={closeMenu}
                    to="/book-design"
                    activeClassName="text-black"
                    className="rounded-t hover:bg-[##5c5e24] py-4 px-5 text-base font-medium block whitespace-no-wrap"
                    style={{
                      width: "200px",
                    }}
                  >
                    Book Design
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={closeMenu}
                    to="/book-editing"
                    activeClassName="text-black"
                    className="hover:bg-[##5c5e24] py-4 px-5 text-base font-medium block whitespace-no-wrap"
                    style={{
                      width: "200px",
                    }}
                  >
                    Book Editing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={closeMenu}
                    to="/marketing"
                    activeClassName="text-black"
                    className="hover:bg-[##5c5e24] py-4 px-5 text-base font-medium block whitespace-no-wrap"
                    style={{
                      width: "200px",
                    }}
                  >
                    Marketing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={closeMenu}
                    to="/distribution"
                    activeClassName="text-black"
                    className="hover:bg-[##5c5e24] py-4 px-5 text-base font-medium block whitespace-no-wrap"
                    style={{
                      width: "200px",
                    }}
                  >
                    Distribution
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={closeMenu}
                    to="/others"
                    activeClassName="text-black"
                    className="rounded-b hover:bg-[##5c5e24] py-4 px-5 text-base font-medium block whitespace-no-wrap"
                    style={{
                      width: "200px",
                    }}
                  >
                    Others
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
          <NavLink
            onClick={toggleMenu}
            to="/contact"
            activeClassName="text-black"
            className="block sm:inline-block mt-2 sm:mt-0 text-white hover:text-black font-semibold text-lg"
          >
            Contact
          </NavLink>
        </div>
        <div>
          <NavLink
            onClick={toggleMenu}
            to="/login"
            className="inline-block mt-8 sm:mt-0 py-2 px-8 text-black text-center bg-white rounded-md font-semibold shadow-md w-full sm:w-auto text-lg hover:bg-[#f9671d] hover:text-white"
          >
            Submit Book Online
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
