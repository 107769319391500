import React, { useEffect, useState } from "react";
import { IoMdMail } from "react-icons/io";
import "./BrandBar.css"; // Import CSS file for custom typing animation

const Brandbar = () => {
  const [typedText, setTypedText] = useState("");
  const text = "One Stop Destination for Quality Publication";
  const typingSpeed = 100; // Adjust typing speed as needed
  const delayBeforeTyping = 1000; // Delay before starting typing animation

  useEffect(() => {
    let isUnmounted = false;

    const typeText = async () => {
      if (isUnmounted) return;
      for (let i = 0; i <= text.length; i++) {
        setTypedText(text.substring(0, i));
        await new Promise((resolve) => setTimeout(resolve, typingSpeed));
      }
      await new Promise((resolve) => setTimeout(resolve, delayBeforeTyping));
      setTypedText("");
      typeText();
    };

    setTimeout(typeText, delayBeforeTyping);

    return () => {
      isUnmounted = true;
    };
  }, []);

  return (
    <section className="flex flex-col md:flex-row justify-between items-center px-4 sm:px-6 md:px-12 py-2">
      <div className="flex flex-col md:flex-row items-center md:items-start text-center md:text-left">
        <img
          className="mx-auto md:mx-0 w-20 md:w-24 mb-2 md:mb-0"
          src="/assets/images/logo.png"
          alt="brand logo"
        />
        <div className="mt-1 md:mt-1 md:ml-4">
          <h2 className="font-bold text-xl md:text-2xl text-[#A7AB43]">
            Prominent Publications LLP
          </h2>
          <h3 className="font-extralight text-sm md:text-lg">{typedText}</h3>
        </div>
      </div>

      <div className="mt-4 md:mt-0">
        <div className="flex justify-center md:justify-start items-center gap-2 md:gap-4">
          <IoMdMail size={24} className="text-lg md:text-2xl" />
          <a
            className="underline text-xs md:text-sm"
            href="mailto:prominentpublicationhouse@gmail.com"
          >
            prominentpublicationhouse@gmail.com
          </a>
        </div>
        <div className="flex justify-center mt-2 md:mt-3 gap-3 md:gap-4">
          <a href="https://facebook.com" rel="noreferrer" target="_blank">
            <img src="/assets/images/fb.png" alt="fbnav" className="w-5 md:w-6" />
          </a>
          <a href="https://instagram.com" rel="noreferrer" target="_blank">
            <img src="/assets/images/ig.png" alt="ignav" className="w-5 md:w-6" />
          </a>
          <a href="https://twitter.com" rel="noreferrer" target="_blank">
            <img src="/assets/images/tw.png" alt="twnav" className="w-5 md:w-6" />
          </a>
          <a href="https://linkedin.com" rel="noreferrer" target="_blank">
            <img src="/assets/images/in.png" alt="lnnav" className="w-5 md:w-6" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Brandbar;
