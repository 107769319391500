import React, { useRef, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Form from "../../components/common/Form";
import TopBanner from "../../components/common/TopBanner";

const Royality = () => {
  const faqArr = [
    {
      id: 1,
      que: "Q. What services do you provide?",
      ans: "The wanna be authors can create, print, and sell their books to readers in all nooks and crannies of the world. Not just books, we assist in printing thesis, research works, and writings for journals.",
    },
    {
      id: 2,
      que: "Q. What is Approximate MRP(iNR)?",
      ans: "Approximate MRP is the lowest price that you can fix for your book. This price includes the printing and distribution cost. The author royalty calculator will tell you",
    },
    {
      id: 3,
      que: "Q. What are the factors that can influence the approximate MRP of a book?",
      ans: "The approximate MRP is influenced by the total number of pages, color pages in a book, the type of cover - hard cover or paperback, and the size of the book.",
    },
    {
      id: 4,
      que: "Q. How much is the royalty and how is it calculated?",
      ans: "The author royalty is 100% of net profit and the net profit is calculated by subtracting the book production cost and the distribution cost from the MRP.",
    },
  ];

  const BookPageRef = useRef(null);
  const MrpBookRef = useRef(null);
  const MrpRoyRef = useRef(null);
  const PpllpRef = useRef(null);
  const OtbRef = useRef(null);

  const [openFaqId, setOpenFaqId] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const toggleFaq = (id) => {
    if (openFaqId === id) {
      setOpenFaqId(null); // Close the accordion if it's already open
    } else {
      setOpenFaqId(id); // Open the clicked accordion item
    }
  };

  function handleRoyaltySubmit(e) {
    e.preventDefault();

    if (MrpRoyRef.current.value.trim() !== null) {
      const MrpRoy = Number(MrpRoyRef.current.value.trim());

      const PPLLP_Store_Benefit = 0.7;
      const Other_Store_Benefit = 0.3;

      PpllpRef.current.value = (PPLLP_Store_Benefit * MrpRoy).toFixed(2);
      OtbRef.current.value = (Other_Store_Benefit * MrpRoy).toFixed(2);
    }
  }

  function handleMrpSubmit(e) {
    e.preventDefault();

    if (BookPageRef.current.value.trim() !== null) {
      let TotalBookPages = BookPageRef.current.value.trim();
      let ApproximateMRP = 2.0 * TotalBookPages;

      MrpBookRef.current.value = ApproximateMRP.toFixed(2);
      MrpRoyRef.current.value = ApproximateMRP.toFixed(2);
    }
  }

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/bookstore.png"} />

      <section
        className="p-4 md:p-14 grid grid-cols-1 gap-4"
        data-aos="fade-up"
      >
        <div className="flex items-center gap-5">
          <div className="w-3 h-12 bg-[#A7AB43]"></div>
          <h3 className="text-[#A7AB43] font-semibold text-xl md:text-2xl">
            Royalty
          </h3>
        </div>
      </section>
      <section
        className="p-4 md:p-14 grid grid-cols-1 md:grid-cols-2 gap-4"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="flex flex-col items-center gap-3">
          <form
            className="w-full md:w-[75%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md"
            onSubmit={handleMrpSubmit}
          >
            <div className="w-full text-center bg-[#A7AB43] py-6 px-10 rounded-md">
              <h3 className="mt-2 font-medium text-xl">MRP Calculator</h3>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-6 px-6">
              <div>
                <label
                  htmlFor="bookp"
                  className="block font-medium text-gray-700"
                >
                  Total Book Pages
                </label>
                <input
                  id="bookp"
                  name="bookp"
                  type="number"
                  placeholder="Enter No. of Pages"
                  className="mt-1 w-full border-2 border-gray-500 p-3 rounded-md"
                  ref={BookPageRef}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="mrp"
                  className="block font-medium text-gray-700"
                >
                  Approximate MRP(INR)
                </label>
                <input
                  id="mrp"
                  name="mrp"
                  type="text"
                  placeholder="Calculated MRP"
                  className="mt-1 w-full border-2 border-gray-500 p-3 rounded-md"
                  ref={MrpBookRef}
                  readOnly
                />
              </div>
              <div className="w-full flex justify-center mt-4 mb-6">
                <button className="text-center text-white font-semibold uppercase py-3 px-6 bg-[#f9671d] rounded-lg shadow-md">
                  Calculate MRP
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="flex flex-col justify-center items-center gap-3">
          <form
            className="w-full md:w-[75%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md"
            onSubmit={handleRoyaltySubmit}
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="w-full text-center bg-[#A7AB43] py-6 px-10">
              <h3 className="mt-2 font-medium text-xl">
                Author Royalty Calculator
              </h3>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-6 px-6">
              <div>
                <label
                  htmlFor="mrpp"
                  className="block font-medium text-gray-700"
                >
                  Enter the MRP
                </label>
                <input
                  id="mrpp"
                  name="mrpp"
                  type="number"
                  placeholder="Enter MRP (INR)"
                  className="mt-1 w-full border-2 border-gray-500 p-3 rounded-md"
                  ref={MrpRoyRef}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="ppllp"
                  className="block font-medium text-gray-700"
                >
                  PPLLP Store Benefit
                </label>
                <input
                  id="ppllp"
                  name="ppllp"
                  type="text"
                  placeholder="PPLLP Store"
                  className="mt-1 w-full border-2 border-gray-500 p-3 rounded-md"
                  ref={PpllpRef}
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="otb"
                  className="block font-medium text-gray-700"
                >
                  Other Store Benefit
                </label>
                <input
                  id="otb"
                  name="otb"
                  type="text"
                  placeholder="Other Store"
                  className="mt-1 w-full border-2 border-gray-500 p-3 rounded-md"
                  ref={OtbRef}
                  readOnly
                />
              </div>
              <div className="w-full flex justify-center mt-4 mb-6">
                <button className="text-center text-white font-semibold uppercase py-3 px-6 bg-[#f9671d] rounded-lg shadow-md">
                  Calculate Royalty
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section
        className="p-4 md:p-14 grid grid-cols-1 gap-4"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        <h2 className="text-[#A7AB43] text-center font-semibold text-2xl tracking-widest mb-4">
          Frequently Asked Questions
        </h2>
        {faqArr.map((faq, index) => (
          <div
            key={faq.id}
            className="border border-gray-500 rounded-md"
            onClick={() => toggleFaq(faq.id)}
            data-aos="fade-up"
            data-aos-delay={`${700 + index * 100}`}
          >
            <p className="p-4 font-semibold cursor-pointer rounded-md">
              {faq.que}
            </p>

            {openFaqId === faq.id && (
              <p className="rounded-md text-justify px-4 py-2 text-gray-700">
                {faq.ans}
              </p>
            )}
          </div>
        ))}
      </section>

      <section
        className="mt-2 mb-6"
        data-aos="fade-up"
        data-aos-delay="1000"
      >
        <Form />
      </section>
    </div>
  );
};

export default Royality;
