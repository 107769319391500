import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './Bookstore.css'
import Form from "../../components/common/Form";
import BookCard from "./BookCard";
import TopBanner from "../../components/common/TopBanner";

const Bookstore = () => {
  const [getBookData, setGetBookData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const bookstore_url =
      "https://prominantbook.azurewebsites.net/api/v1/GetAllBookInBookStore";
    const fetchBookData = async () => {
      try {
        const response = await fetch(bookstore_url);
        const responseData = await response.json();
        setGetBookData(responseData.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };
    fetchBookData();
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/bookstore.png"} />
      <section className="grid grid-cols-1 p-10 pb-4 gap-2" data-aos="fade-up">
        <div className="flex items-center gap-5">
          <div className="w-3 h-12 bg-[#A7AB43]"></div>
          <h3 className="text-[#A7AB43] font-semibold text-xl md:text-2xl">
            Book Store
          </h3>
        </div>
      </section>
      <section
        className="p-5 md:p-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {loading ? (
          <div className="flex justify-center items-center col-span-1 md:col-span-2 lg:col-span-3">
            <div className="loading-message">
              <div className="loader"></div>
              <p>Loading books...</p>
            </div>
          </div>
        ) : (
          getBookData.map((book, index) => (
            <BookCard 
              key={book.BookId}
              {...book}
              data-aos="fade-up"
              data-aos-delay={200 + index * 100} // Increment delay for each book
            />
          ))
        )}
      </section>
      <section className="mt-2 mb-6" data-aos="fade-up" data-aos-delay="400">
        <Form />
      </section>
    </div>
  );
};

export default Bookstore;

