import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Bookstore.css"
const BookCard = ({ BookId, NameofBook, BookAuthor, BookPrice }) => {
  const [bookCover, setBookCover] = useState("");

  useEffect(() => {
    const fetchBookCover = async () => {
      try {
        const response = await fetch(
          `https://prominantbook.azurewebsites.net/api/v1/getDocumentbyDocumentId/${BookId}`
        );
        const data = await response.json();
        if (data && data.data && data.data.length > 0) {
          const cover = data.data[0].files[0];
          setBookCover(cover);
        }
      } catch (error) {
        console.error("Error fetching book cover: ", error);
      }
    };

    fetchBookCover();
  }, [BookId]);

  return (
    <div className="w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-6 px-10 book-box">
      <img
        className="aspect-[1/1]"
        src={bookCover || "/placeholder.jpg"}
        alt={`${NameofBook}${BookAuthor}`}
      />
      <h3 className="mt-2 font-medium text-xl text-center">{NameofBook}</h3>
      <p className="mt-2 text-lg text-center">Author: {BookAuthor}</p>
      <p className="mt-2 text-lg text-center">Rs. {BookPrice}/-</p>
      <div className="w-full flex justify-center mt-2">
        <Link
          className="w-[50%] text-center text-white py-3 px-6 bg-[#A7AB43] place-items-center rounded-full shadow-md"
          to={`/bookstore/${BookId}`}
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default BookCard;
