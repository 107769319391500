import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <footer className="grid grid-cols-1 md:grid-cols-3 justify-items-center p-4 md:p-6 border-2 border-[#A7AB43] mx-auto" data-aos="fade-up">
      <div className="flex flex-col items-center mb-6 md:mb-0" data-aos="fade-up" data-aos-delay="200">
        <img
          className="w-24 md:w-32 mb-2"
          src="/assets/images/logo.png"
          alt="brandlogofooter"
        />
        <p className="text-center md:text-justify text-sm md:text-base">
          For authors all around the world, Prominent Publication is the top
          supplier of book publishing services. We give you the knowledge and
          resources you need to fulfil your publishing aspirations with our
          extensive selection of book publishing packages and services.
        </p>
      </div>
      <div className="flex flex-col items-center gap-2 mb-6 md:mb-0" data-aos="fade-up" data-aos-delay="400">
        <h3 className="text-[#A7AB43] font-semibold text-lg">
          Important Links
        </h3>
        <Link to="/about" className="font-medium">
          About Us
        </Link>
        <Link to="/publishing-plan" className="font-medium">
          Publishing Plan
        </Link>
        <Link to="/bookstore" className="font-medium">
          Bookstore
        </Link>
        <Link to="/Royalty" className="font-medium">
          Royalty
        </Link>
        <Link to="/services" className="font-medium">
          Services
        </Link>
      </div>
      <div className="flex flex-col items-center gap-2" data-aos="fade-up" data-aos-delay="600">
        <h3 className="text-[#F9671D] font-semibold text-lg">Head Office</h3>
        <p className="text-center md:text-left text-sm md:text-base">
          42, Keshav City, Vrindavan Nagar,
          <br /> Sindhi(M), Wardha- 442001,
          <br /> Maharashtra, India.
        </p>
        <p className="text-sm md:text-base">
          Email:{" "}
          <a
            className="underline"
            href="mailto:prominentpublicationhouse@gmail.com"
          >
            prominentpublicationhouse@gmail.com
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
