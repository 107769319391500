import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import Modal from "../../components/common/Modal";

export default function Register() {
  const usernameRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const cnfpasswordRef = React.useRef(null);

  const cancelButtonRef = React.useRef(null);

  const navigate = useNavigate();

  const [error, setError] = React.useState({
    errorTitle: " Registration Error",
    errorMessage: null,
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [success, setSuccess] = React.useState(false);

  async function handleRegisterSubmit(e) {
    e.preventDefault();
    setLoading(true);

    let formData = {
      username: usernameRef?.current?.value.toString(),
      email: emailRef?.current?.value.toString(),
      pwd: passwordRef?.current?.value.toString(),
      cnfpwd: cnfpasswordRef?.current?.value.toString(),
    };

    const emailPattern = new RegExp(
      "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}"
    );

    const passwordPattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
    );

    if (formData?.username?.trim()?.length < 2) {
      setError({ ...error, errorMessage: "Please enter a valid full name" });
      setOpenDialog(true);
    } else if (!emailPattern.test(formData?.email)) {
      setError({
        ...error,
        errorMessage: "Please enter a valid Email Address",
      });
      setOpenDialog(true);
    } else if (!passwordPattern.test(formData?.pwd)) {
      setError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setOpenDialog(true);
    } else if (formData?.pwd !== formData?.cnfpwd) {
      setError({ ...error, errorMessage: "Password do not match!!" });
      setLoading(false);
      setOpenDialog(true);
    } else {
      const registerUserURL =
        "https://prominantbook.azurewebsites.net/api/v1/createUserDetails";
      const sendData = {
        Email: formData?.email,
        UserName: formData?.username,
        Password: formData?.pwd,
      };
      try {
        const response = await fetch(registerUserURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(sendData),
        });
        if (!response.ok) {
          setError({
            ...error,
            errorMessage: "Network response was not ok",
          });
          setOpenDialog(true);
        }
        const result = await response.json();
        console.log(result);
        if (result.success) {
          setSuccess(true);
        } else {
          setError({
            ...error,
            errorMessage: "Registration failed. Please try again.",
          });
          setOpenDialog(true);
        }
      } catch (error) {
        setError({
          ...error,
          errorMessage: "Registration failed. Please try again.",
        });
        setOpenDialog(true);
      } finally {
        usernameRef.current.value = "";
        emailRef.current.value = "";
        passwordRef.current.value = "";
        cnfpasswordRef.current.value = "";
        setLoading(false);
      }
    }
  }

  function closeModal() {
    setOpenDialog(false);
    setError({ ...error, errorMessage: null });
  }

  function successClose() {
    setSuccess(false);
    navigate("/login");
  }

  return (
    <>
      <div className="flex flex-col md:flex-row bg-gray-100">
        <div className="md:w-1/2 flex items-center justify-center">
          <img
            src={"/assets/images/banner.png"}
            alt="Register"
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className="md:w-1/2 flex items-center justify-center p-4 bg-white shadow-md">
          <div className="w-full max-w-md">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-2 h-10 bg-[#A7AB43]"></div>
              <h1 className="text-3xl font-semibold  text-[#A7AB43]">
                Register
              </h1>
            </div>
            <form onSubmit={handleRegisterSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="fullName"
                  className="block text-sm text-gray-700"
                >
                  Full Name
                </label>
                <input
                  ref={usernameRef}
                  autoComplete="off"
                  type="text"
                  id="fullName"
                  name="fullName"
                  className="mt-1 p-1.5 px-2 w-full border rounded"
                  placeholder="eg. John Doe"
                  minLength={2}
                  title="Enter your proper name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm text-gray-700">
                  Email
                </label>
                <input
                  ref={emailRef}
                  autoComplete="off"
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="mt-1 p-1.5 px-2 w-full border rounded"
                  pattern="[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}"
                  placeholder="eg. you@example.com"
                  title="Please enter a valid email address."
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-700"
                >
                  Password
                </label>
                <input
                  ref={passwordRef}
                  autoComplete="off"
                  type="password"
                  id="password"
                  name="password"
                  className="mt-1 p-1.5 px-2 w-full border rounded"
                  placeholder="********"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                  title="Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm text-gray-700"
                >
                  Confirm Password
                </label>
                <input
                  ref={cnfpasswordRef}
                  autoComplete="off"
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className="mt-1 p-1.5 px-2 w-full border rounded"
                  placeholder="********"
                  title="Please make sure that both password matches"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4  text-white rounded bg-[#A7AB43] hover:bg-[#969c0c] "
                disabled={loading}
                ref={cancelButtonRef}
              >
                {loading ? "Wait...." : "Register"}
              </button>
            </form>
            <div className="text-center mt-5 pb-4">
              <p className="text-sm text-gray-600 text-left">
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        openDialog={openDialog}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
        error={error}
      />

      <SuccessModal show={success} onClose={successClose} />
    </>
  );
}

function SuccessModal({ show, onClose }) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Registration successful
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You have been successfull !! Please visit login page
                          to login to your account.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onClose}
                  >
                    Go to Login Page
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
