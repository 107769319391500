import React from "react";
import { Link } from "react-router-dom";
import Modal from "../../components/common/Modal";

export default function Login() {
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = React.useState({
    errorTitle: "Login Error",
    errorMessage: null,
  });
  const [openDialog, setOpenDialog] = React.useState(false);

  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);

  const cancelButtonRef = React.useRef(null);

  function closeModal() {
    setOpenDialog(false);
    setError({ ...error, errorMessage: null });
  }

  async function handleLoginSubmit(e) {
    e.preventDefault();
    setLoading(true);
  
    let formData = {
      email: emailRef.current.value.trim(),
      pwd: passwordRef.current.value,
    };
    
    console.log("Login data:", formData);
  
    const userLoginURL = "https://prominantbook.azurewebsites.net/api/v1/UserLogin";
  
    try {
      const response = await fetch(userLoginURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      console.log("Response:", response);
  
      if (!response.ok) {
        console.error("Network response was not ok");
        setError({
          ...error,
          errorMessage: "Network response was not ok",
        });
        setOpenDialog(true);
        return;
      }
  
      const result = await response.json();
  
      console.log("Login result:", result);
  
      if (result.success === false && result.message === "Plese enter your correct Email") {
        console.error("Please enter a correct email address.");
        setError({
          ...error,
          errorMessage: "Please enter a correct email address.",
        });
        setOpenDialog(true);
      } else if (result.success === true) {
        // Handle successful login, e.g., save token, redirect, etc.
        console.log("logged in successfully");
      } else {
        console.error("You have entered an invalid email or password.");
        setError({
          ...error,
          errorMessage: "You have entered an invalid email or password.",
        });
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError({
        ...error,
        errorMessage: "An error occurred during login.",
      });
      setOpenDialog(true);
    } finally {
      emailRef.current.value = "";
      passwordRef.current.value = "";
      setLoading(false);
    }
  }
  
  
  return (
    <>
      <div className="flex flex-col md:flex-row bg-gray-100">
        <div className="md:w-1/2 flex items-center justify-center">
          <img
            src={"/assets/images/bookstore.png"}
            alt="Login"
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className="md:w-1/2 flex items-center justify-center p-4 bg-white shadow-md">
          <div className="w-full max-w-md">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-2 h-10 bg-[#A7AB43]"></div>
              <h1 className="text-3xl font-semibold  text-[#A7AB43]">Login</h1>
            </div>
            <form onSubmit={handleLoginSubmit}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">
                  Email
                </label>
                <input
                  ref={emailRef}
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="mt-2 p-2 w-full border rounded"
                  pattern="[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}"
                  placeholder="eg. you@example.com"
                  title="Please enter a valid email address."
                  autoComplete="off"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-gray-700">
                  Password
                </label>
                <input
                  ref={passwordRef}
                  type="password"
                  id="password"
                  name="password"
                  className="mt-2 p-2 w-full border rounded"
                  placeholder="********"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-[#A7AB43] text-white rounded hover:bg-[#969c0c]"
              >
                {loading ? "Wait...." : "Login"}
              </button>
            </form>
            <div className="text-center mt-5 pb-4">
              <p className="text-sm text-gray-600 text-left">
                Don't have an account?{" "}
                <Link
                  to="/register"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        openDialog={openDialog}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
        error={error}
      />
    </>
  );
}
