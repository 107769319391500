import React, { useRef, useState, useEffect } from "react";
import { HiOutlineClock, HiOutlineMail } from "react-icons/hi";
import TopBanner from "../../components/common/TopBanner";
import Form from "../../components/common/Form";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const NameRef = useRef(null);
  const EmailRef = useRef(null);
  const MobileRef = useRef(null);
  const MessageRef = useRef(null);

  useEffect(() => {
    AOS.init({ once: false });
  }, []);

  async function handleContactDataSubmit(e) {
    e.preventDefault();

    const regEmail = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
    );
    const regPhone = /^[1-9][0-9]{7,9}$/;

    const nameCheck = NameRef.current.value.trim().length >= 2;
    const emailCheck = regEmail.test(EmailRef.current.value.trim());
    const phoneCheck = regPhone.test(MobileRef.current.value.trim());

    if (
      nameCheck &&
      emailCheck &&
      phoneCheck &&
      MessageRef.current.value.trim()
    ) {
      const enquiryData = {
        Name: NameRef.current.value.trim(),
        Email: EmailRef.current.value.trim(),
        Mobile: MobileRef.current.value.trim(),
        Message: MessageRef.current.value.trim(),
      };

      try {
        const response = await fetch(
          "https://prominantbook.azurewebsites.net/api/v1/CreateContactDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(enquiryData),
          }
        );

        if (response.ok) {
          setSubmissionStatus("success");
          // Clear form fields
          NameRef.current.value = "";
          EmailRef.current.value = "";
          MobileRef.current.value = "";
          MessageRef.current.value = "";
        } else {
          setSubmissionStatus("failure");
        }
      } catch (error) {
        console.error("An error occurred while submitting the enquiry", error);
        setSubmissionStatus("failure");
      }
    } else {
      console.error("Validation failed");
      setSubmissionStatus("validationError");
    }
  }

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/contact.png"} />
      <section
        className="grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-4"
        data-aos="fade-up"
      >
        <div className="flex flex-col justify-center items-center gap-3 ">
          <div
            className="w-full md:w-[75%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-6 px-10"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <HiOutlineClock className="text-[#F9671D] text-5xl" />
            <h3 className="mt-2 font-semibold text-xl md:text-2xl text-[#A7AB43]">
              Working Hours
            </h3>
            <p className="mt-4 text-lg text-center">
              We appreciate you respecting our available time. <br />
              24x7
            </p>
          </div>
          <div
            className="w-full md:w-[75%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-6 px-10"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <HiOutlineMail className="text-[#F9671D] text-5xl" />
            <h3 className="mt-2 font-semibold text-xl md:text-2xl text-[#A7AB43]">
              Mail Us
            </h3>
            <p className="mt-4 text-lg text-center">
              Drop us a message. <br />
              We’ll be glad to answer it.
            </p>
            <p className="mt-4 text-lg text-center font-semibold">
              prominentpublicationhouse@gmail.com
            </p>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="400">
          <form
            className="flex flex-col gap-2"
            onSubmit={handleContactDataSubmit}
          >
            <input
              id="fname"
              name="Name"
              type="text"
              ref={NameRef}
              placeholder="Enter Your Name"
              className="w-full border-2 border-gray-500 p-3 mt-2 rounded"
              required
              data-aos="fade-up"
              data-aos-delay="500"
            />
            <input
              id="email"
              name="Email"
              type="email"
              ref={EmailRef}
              placeholder="Enter Your Email"
              className="w-full border-2 border-gray-500 p-3 mt-2 rounded"
              required
              data-aos="fade-up"
              data-aos-delay="600"
            />
            <input
              id="phone"
              name="Mobile"
              type="tel"
              ref={MobileRef}
              placeholder="Enter Your Mobile Number"
              className="w-full border-2 border-gray-500 p-3 mt-2 rounded"
              minLength={8}
              maxLength={10}
              required
              data-aos="fade-up"
              data-aos-delay="700"
            />
            <textarea
              id="message"
              name="Message"
              placeholder="Enter Your Message"
              ref={MessageRef}
              className="w-full border-2 border-gray-500 p-3 mt-2 rounded"
              required
              data-aos="fade-up"
              data-aos-delay="800"
            />
            <div
              className="flex justify-center mt-4"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              <button
                type="submit"
                className="w-full text-white bg-[#F9671D] hover:bg-[#dc2f02] font-semibold py-2 px-4 rounded shadow-lg"
              >
                Submit
              </button>
            </div>
          </form>
          {submissionStatus === "success" && (
            <p className="mt-4 text-center text-green-600">
              Thank you! We will reach out to you soon.
            </p>
          )}
          {submissionStatus === "failure" && (
            <p className="mt-4 text-center text-red-600">
              Failed to submit your enquiry. Please try again later.
            </p>
          )}
          {submissionStatus === "validationError" && (
            <p className="mt-4 text-center text-red-600">
              Please ensure all fields are correctly filled out.
            </p>
          )}
        </div>
      </section>
      <section className="mt-2 mb-6">
        <Form />
      </section>
    </div>
  );
};

export default Contact;
