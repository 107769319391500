import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Form from "../../../components/common/Form";
import TopBanner from "../../../components/common/TopBanner";

const BookDesign = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/banner.png"} />
      <section className="grid grid-cols-1 p-5 md:p-10 gap-2">
        <div data-aos="fade-up">
          <div className="flex items-center gap-5" data-aos="fade-up">
            <div className="w-3 h-12 bg-[#A7AB43]" data-aos="fade-up"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl" data-aos="fade-up">
              Book Design
            </h3>
          </div>
          <h4 className="text-lg mt-8 tracking-widest font-bold" data-aos="fade-up" data-aos-delay="100">
            Basic Cover Design
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up" data-aos-delay="200">
            The reader's first impression of a book is shaped by its cover. We
            make sure your book has the finest cover possible. In a basic cover
            design, we choose two covers for the author to choose from and
            discover appropriate images that go with the material in a variety
            of stock photo repositories.
          </p>
          <p className="text-lg mt-2 tracking-widest font-medium" data-aos="fade-up" data-aos-delay="300">
            Price: ₹ 2,500 plus GST.
          </p>
          <h4 className="text-lg mt-8 tracking-widest font-bold" data-aos="fade-up" data-aos-delay="400">
            Basic Book Interior Design
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up" data-aos-delay="500">
            This service involves designing a book's inside using basic design
            templates. The number of photographs and tables in the book is
            unlimited. No changes will be made to any of these images or tables
            from our end; they must all be supplied by the author. Both fiction
            and non-fiction books benefit from this kind of interior design.
          </p>
          <p className="text-lg mt-2 tracking-widest font-medium" data-aos="fade-up" data-aos-delay="600">
            Price: ₹ 5,000 plus GST.
          </p>
        </div>
      </section>
      <section className="mt-2 mb-6" data-aos="fade-up" data-aos-delay="700">
        <Form />
      </section>
    </div>
  );
};

export default BookDesign;
