import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdTimer, MdOutlineMenuBook } from "react-icons/md";
import './Home.css'
import Form from "../../components/common/Form";
import TopBanner from "../../components/common/TopBanner";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/bookstore.png"} />
      <section className="grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-2 who-we-are ">
        <div data-aos="fade-up">
          <div className="flex items-center gap-4 wwhead">
            <div className="w-3 h-12 bg-[#A7AB43]"></div>
            <h3 className="text-[#A7AB43] font-semibold text-xl md:text-2xl  ">
              WHO WE ARE?
            </h3>
          </div>
          <p className="text-lg mt-5 tracking-widest wwpara " data-aos="fade-up">
            Do you lack knowledge regarding the publishing process for your
            book? Prominent Publication LLP, one of India's most well-known book
            publishing platforms, will help you get your book published. We
            assist authors in publishing their books. In addition to poetry and
            regional publications, we also publish fiction, nonfiction, and
            works of art, science, and technology.
          </p>
          <div className="flex justify-center mt-6 wwpara" data-aos="fade-up">
            <Link
              className="text-white py-3 px-6 bg-black rounded-full shadow-md hover:bg-[#F9671D]"
              to="/"
            >
              Read More
            </Link>
          </div>
        </div>
        <div className="flex justify-center items-center mt-4 md:mt-0" data-aos="fade-up">
          <img
            className="w-[90%] md:w-[75%]"
            src="/assets/images/whoarewe.png"
            alt="whoarewe"
          />
        </div>
      </section>
      <section className="text-center my-4 p-5 md:p-10" data-aos="fade-up">
        <h2 className="uppercase text-[#A7AB43] font-semibold text-xl md:text-2xl tracking-widest">
          Why you will love Prominent Publications?
        </h2>
      </section>
      <section className="p-5 md:p-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
        {/* Service Block: 24x7 Support */}
        <div className="w-full md:w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-4 md:py-6 px-4 md:px-10 bo" data-aos="fade-up">
          <MdTimer size={60} />
          <h3 className="mt-2 font-medium text-lg md:text-xl">24x7 Support</h3>
          <p className="text-sm md:text-lg text-justify">
            We are always there to assist you. Receive frequent updates by phone
            or email.
          </p>
        </div>
        {/* Service Block: Publication Transparency */}
        <div className="w-full md:w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-4 md:py-6 px-4 md:px-10" data-aos="fade-up">
          <MdOutlineMenuBook size={60} />
          <h3 className="mt-2 font-medium text-lg md:text-xl">
            Publication Transparency
          </h3>
          <p className="text-sm md:text-lg text-justify">
            You will be guided through each step of the book publishing process
            in order to uphold the highest levels of trust and transparency.
          </p>
        </div>
        {/* Service Block: Online Sales Monitoring */}
        <div className="w-full md:w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-4 md:py-6 px-4 md:px-10" data-aos="fade-up">
          <img className="w-20" src="/assets/images/Sales.png" alt="osm" />
          <h3 className="mt-2 font-medium text-lg md:text-xl">
            Online Sales Monitoring
          </h3>
          <p className="text-sm md:text-lg text-justify">
            You may view all the information about each book's sales and
            royalties received on your specially created author dashboard.
          </p>
        </div>
        {/* Service Block: Non-Exclusive Agreement */}
        <div className="w-full md:w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-4 md:py-6 px-4 md:px-10" data-aos="fade-up">
          <img className="w-16" src="/assets/images/Vector.png" alt="revenue" />
          <h3 className="mt-2 font-medium text-lg md:text-xl">
            Non-Exclusive Agreement
          </h3>
          <p className="text-sm md:text-lg text-justify">
            You retain all rights for your books. We just retain publishing
            rights.
          </p>
        </div>
        {/* Service Block: Profit Sharing */}
        <div className="w-full md:w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md py-4 md:py-6 px-4 md:px-10" data-aos="fade-up">
          <img
            className="w-20"
            src="/assets/images/Revenue.png"
            alt="revenue"
          />
          <h3 className="mt-2 font-medium text-lg md:text-xl">
            Profit Sharing
          </h3>
          <p className="text-sm md:text-lg text-justify">
            We Provide the highest Royalty on Book Selling, get Royalty on a
            monthly basis.
          </p>
        </div>
      </section>
      <section className="p-5 md:p-10 text-center mt-2 mb-6" data-aos="fade-up">
        <h2 className="uppercase text-[#A7AB43] font-semibold text-xl md:text-2xl tracking-widest">
          How to Publish a Book?
        </h2>
        <p className="text-black text-lg md:text-xl font-light mt-3">
          Publish your work in 5 easy steps. The steps that will lead you
          through the book publishing process with us are listed below.
        </p>
      </section>
      {/* Remaining sections */}
      <section className="text-center mt-2" data-aos="fade-up">
        <h2 className="uppercase text-[#A7AB43] font-semibold text-xl md:text-2xl tracking-widest">
          What our Authors got to say!
        </h2>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-2">
        <div className="flex flex-col gap-4 justify-center text-justify" data-aos="fade-up">
          <p className="text-md md:text-lg tracking-widest wwpara">
            Every story is worthy of being recognized. Being a part of all these
            incredible and motivating experiences makes us proud.
          </p>
          <p className="text-md md:text-lg tracking-widest wwpara">
            The fulfilment that a good title can provide to our authors is what
            matters most to us.
          </p>
          <p className="text-md md:text-lg tracking-widest wwpara">
            Our gratitude for their warm words is overwhelming.
          </p>
        </div>
        <div className="flex justify-center items-center mt-4 md:mt-0" data-aos="fade-up">
          <img
            className="w-[90%] md:w-[75%]"
            src="/assets/images/autrec.png"
            alt="author testimonials"
          />
        </div>
      </section>
      <section className="mt-2 mb-6" data-aos="fade-up">
        <Form />
      </section>
    </div>
  );
};

export default Home;
