import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Brandbar from './components/BrandBar/BrandBar';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Footer from './components/Footer/Footer';
import PublishingPlan from './pages/PublishingPlan/PublishingPlan';
import Bookstore from './pages/Bookstore/Bookstore';
import BookDetail from './pages/BookDetail/BookDetail';
import Contact from './pages/Contact/Contact';
import Royalty from './pages/Royality/Royality';
import BookDesign from './pages/Services/BookDesign/BookDesign';
import BookEditing from './pages/Services/BookEditing/BookEditing';
import BookMarketing from './pages/Services/BookMarketing/BookMarketing';
import BookDistrbutions from './pages/Services/BookDistrbutions/BookDistrbutions';
import OtherServices from './pages/Services/OtherServices/OtherServices';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AdminRedirect() {
  useEffect(() => {
    const adminUrl = process.env.REACT_APP_ADMIN_URL;
    console.log('Admin URL:', adminUrl); // Debugging statement
    window.location.href = adminUrl;
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <Brandbar />
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/publishing-plan" element={<PublishingPlan />} />
        <Route exact path="/bookstore" element={<Bookstore />} />
        <Route exact path="/bookstore/:id" element={<BookDetail />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/royalty" element={<Royalty />} />
        <Route exact path="/book-design" element={<BookDesign />} />
        <Route exact path="/book-editing" element={<BookEditing />} />
        <Route exact path="/marketing" element={<BookMarketing />} />
        <Route exact path="/distribution" element={<BookDistrbutions />} />
        <Route exact path="/others" element={<OtherServices />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/admin" element={<AdminRedirect />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
