import React from "react";
import { MdCheck } from "react-icons/md";

const PlanOne = () => {
  return (
    <div className="w-[80%] flex flex-col items-center border-2 border-gray-200 rounded-md shadow-md">
      <div className="text-center bg-[#FFC01F] py-6 px-10">
        <h3 className="mt-2 font-medium text-xl">STARTER</h3>
        <p className="mt-2 text-justify">
          Our professionals can help you publish your book in a lovely format.
        </p>
      </div>
      <div className="py-4 px-10 text-center">
        <h3 className="mt-2 font-medium text-xl">₹ 2999</h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Guided publishing</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">
              Dedicated Publishing Manager
            </span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Cover Design
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Standard Cover Design</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>1 Cover Sample</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Paperback cover</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">Revisions*</span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Interior Designing
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Paperback & ebook</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>B/W Interior Type</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Basic Interior Design</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>2 Interior Revision Round</span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Book Formatting
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">Book Formatting</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Black & White Interior</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">Proof Reading</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">Revisions*</span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Content Security
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">Copyright</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>ISBN</span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Manuscript Editing
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">
              Manuscript Proofreading
            </span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Basic Spelling Correction</span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Marketing Plans
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>E-Author Certificate</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Marketing Guidance</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Online Listing</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">
              Author Website (Add-On)
            </span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-white" size={18} />
          <div>
            <span className="text-[#C4C4C4] line-through">
              Book Video Trailer
            </span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Printing
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Author Copies</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Digital Proof</span>
          </div>
        </div>
      </div>

      <div className="w-full py-4 mb-6 px-10 text-center">
        <h3 className="text-center text-[#F9671D] font-semibold mb-2">
          Royalty & Sales Support
        </h3>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Author Dashboard</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>Monthly Royalty Update</span>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <MdCheck className="mt-1 text-[#F9671D]" size={18} />
          <div>
            <span>70% Profit Share</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanOne;
