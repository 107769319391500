import React, { useEffect } from "react";
import Form from "../../../components/common/Form";
import TopBanner from "../../../components/common/TopBanner";
import AOS from "aos";
import "aos/dist/aos.css";

const OtherServices = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/banner.png"} />

      <section className="grid grid-cols-1 p-14 gap-2" data-aos="fade-up">
        <div>
          <div className="flex items-center gap-5">
            <div className="w w-3 h-12 bg-[#A7AB43]" data-aos="fade-right"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl" data-aos="fade-left">
              Other Services
            </h3>
          </div>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up">
            Publicity has emerged as the most important element in an author's
            success. Your book needs a publicist to stand out from the thousands
            of other books that are published each month.
          </p>
          <h4 className="text-lg mt-4 tracking-widest font-semibold" data-aos="fade-up">
            Author Website
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up">
            Today's authors must must have their own website. It functions as a
            digital platform for your book as well as you as the author. You can
            interact with potential readers on the website in addition to
            promoting your book.
          </p>
          <p className="text-lg mt-1 tracking-widest font-medium" data-aos="fade-up">
            Price: ₹ 9,000 plus GST.
          </p>
          <h4 className="text-lg mt-4 tracking-widest font-semibold" data-aos="fade-up">
            Online Book Ads via Google - 30 days Package
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up">
            Your book ad will appear on content websites and web pages with the
            Online Book Ads via Google service rather than on search results
            pages. These advertisements draw viewers' attention to themselves on
            websites because they are pertinent to their interests, which
            enables the creation of individualised, precise, and timely
            interactions.
          </p>
          <p className="text-lg mt-1 tracking-widest font-medium" data-aos="fade-up">
            Price: ₹ 35,000 plus GST.
          </p>
          <h4 className="text-lg mt-4 tracking-widest font-semibold" data-aos="fade-up">
            Copyright
          </h4>
          <p className="text-lg mt-1 tracking-widest" data-aos="fade-up">
            Your work is shielded against theft or unauthorised use by
            copyright. The Registrar of Copyright, Government of India, is where
            copyright registration is done. After official certification, you
            will receive a copyright certificate. In India, the process to
            register for a copyright takes around a year.
          </p>
          <p className="text-lg mt-1 tracking-widest font-medium" data-aos="fade-up">
            Price: ₹ 3,000 plus GST.
          </p>
        </div>
      </section>
      <section className=" mt-2 mb-6" data-aos="fade-up">
        <Form />
      </section>
    </div>
  );
};

export default OtherServices;
