import React, { useEffect } from "react";
import Form from "../../components/common/Form";
import TopBanner from "../../components/common/TopBanner";
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <TopBanner imagesrc={"/assets/images/abbanner.png"} />
      <section className="grid grid-cols-1 p-14 gap-2">
        <div>
          <div className="flex items-center gap-5" data-aos="fade-up">
            <div className="w w-3 h-12 bg-[#A7AB43]"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl">About Us</h3>
          </div>
          <p className="text-lg mt-5 tracking-widest" data-aos="fade-up" data-aos-delay="200">
            In order to serve our clients with quality services on a limited
            budget utilising cost-effective modern methods, we founded our
            publishing house in 2019.
          </p>
          <p className="text-lg mt-2 tracking-widest" data-aos="fade-up" data-aos-delay="400">
            Since then, we have made significant progress, and as a consequence
            of our efforts to offer high-quality services on a small budget and
            the digital form of communication, we are now leading national book
            publishers with a global client base.
          </p>
          <p className="text-lg mt-2 tracking-widest" data-aos="fade-up" data-aos-delay="600">
            Since we've observed that it can be difficult for our authors to get
            their work published via the conventional method, we've adopted
            modern ways to help them feel more at ease with the publication
            process. As book publishers, it is our responsibility to offer
            writers services like editing, cover design, promotion, and
            distribution of their works. However, what sets us apart from other
            publishing organizations is the distinctive quality we offer.
            Therefore, Prominent Publication assures you services at such low
            prices with a vast selection that you would not have otherwise found
            on a single platform.
          </p>
        </div>
      </section>
      <section className="grid grid-cols-1 p-14 gap-2">
        <div>
          <div className="flex items-center gap-5" data-aos="fade-up">
            <div className="w w-3 h-12 bg-[#A7AB43]"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl">Vision</h3>
          </div>
          <div className="flex items-center gap-2 mt-4" data-aos="fade-up" data-aos-delay="200">
            <img className="w-9" src="/assets/images/like.png" alt="lk" />
            <p className="text-lg mt-2 tracking-widest">
              To Emerge as Renowned & Quality Conscious Publication.
            </p>
          </div>
        </div>
      </section>
      <section className="grid grid-cols-1 p-14 gap-2">
        <div>
          <div className="flex items-center gap-5" data-aos="fade-up">
            <div className="w w-3 h-12 bg-[#A7AB43]"></div>
            <h3 className="text-[#A7AB43] font-semibold text-2xl">Mission</h3>
          </div>
          <div className="flex items-center gap-2 mt-4" data-aos="fade-up" data-aos-delay="200">
            <img className="w-9" src="/assets/images/like.png" alt="like" />
            <p className="text-lg mt-2 tracking-widest">
              Reaching to all levels of students, academician, researchers and
              practitioners with publication facility.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-2 mb-6" data-aos="fade-up" data-aos-delay="200">
        <Form />
      </section>
    </div>
  );
};

export default About;
