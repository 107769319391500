import React, { useRef, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Form = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const regPhone = /^[1-9][0-9]{7,9}$/;

    const emailCheck = regEmail.test(emailRef.current.value.trim());
    const phoneCheck = regPhone.test(phoneRef.current.value.trim());

    if (
      emailCheck &&
      phoneCheck &&
      nameRef.current.value.trim().length >= 2 &&
      messageRef.current.value.trim().length > 0
    ) {
      const formData = {
        Name: nameRef.current.value.trim(),
        Email: emailRef.current.value.trim(),
        Mobile: phoneRef.current.value.trim(),
        Message: messageRef.current.value.trim(),
      };

      setIsLoading(true);

      try {
        const response = await fetch(
          "https://prominantbook.azurewebsites.net/api/v1/CreateContactDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const responseData = await response.json();

        if (response.ok) {
          console.log("Data saved:", responseData);
          setSubmissionStatus("success");
          // Clear form fields
          nameRef.current.value = "";
          emailRef.current.value = "";
          phoneRef.current.value = "";
          messageRef.current.value = "";
        } else {
          console.error("Failed to save data:", responseData);
          setSubmissionStatus("failure");
        }
      } catch (error) {
        console.error("An error occurred while submitting the form", error);
        setSubmissionStatus("failure");
      } finally {
        setIsLoading(false);
      }
    } else {
      setSubmissionStatus("validationError");
    }
  };

  return (
    <>
      <p
        className="text-center text-black text-lg md:text-xl font-semibold tracking-wide mt-3 px-4"
        data-aos="fade-up"
      >
        To Begin Publishing, Please Contact Us.
      </p>
      <form onSubmit={handleSubmit} className="p-5 md:p-10" data-aos="fade-up">
        <div
          className="grid grid-cols-1 sm:grid-cols-3 gap-4 justify-items-center mt-6"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <input
            type="text"
            placeholder="Full Name"
            className="w-full border-2 border-gray-500 p-3"
            ref={nameRef}
            required
          />
          <input
            type="email"
            placeholder="Email Id"
            className="w-full border-2 border-gray-500 p-3"
            ref={emailRef}
            required
          />
          <input
            type="tel"
            placeholder="Mobile Number"
            className="w-full border-2 border-gray-500 p-3"
            ref={phoneRef}
            minLength={8}
            maxLength={10}
            required
          />
        </div>
        <div
          className="flex justify-center mt-6"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <textarea
            id="message"
            name="Message"
            placeholder="Enter Your Message"
            className="w-full sm:w-2/3 lg:w-1/2 border-2 border-gray-500 p-3 rounded"
            ref={messageRef}
            required
          />
        </div>
        <div
          className="flex justify-center mt-6"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <button
            type="submit"
            className="bg-black text-white py-3 px-6 rounded-md shadow-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50"
            disabled={isLoading}
          >
            {isLoading ? "Wait..." : "Get In Touch"}
          </button>
        </div>
      </form>
      {submissionStatus === "success" && (
        <p
          className="mt-4 text-center text-green-600"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          Thank you! We will reach out to you soon.
        </p>
      )}
      {submissionStatus === "failure" && (
        <p
          className="mt-4 text-center text-red-600"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          Failed to submit your enquiry. Please try again later.
        </p>
      )}
      {submissionStatus === "validationError" && (
        <p
          className="mt-4 text-center text-red-600"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          Please ensure all fields are correctly filled out.
        </p>
      )}
    </>
  );
};

export default Form;
